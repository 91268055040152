<template>
  <div class="User">
    用户中心
  </div>
</template>

<script>
export default {};
</script>

<style></style>
